import React, { useState, useEffect } from "react";
import { useIntl, navigate } from "gatsby-plugin-intl";
import { graphql } from "gatsby";

import PageHead from "../components/pageHead";
import TagList from "../components/tagList";
import TeaserCase from "../components/teaserCase";

import Layout from "../layouts/default-layout";

const ProjectsPage = ({ path, data, location }) => {
  const intl = useIntl()
  const { state = {} } = location;
  const selected = state && state.selected ? state.selected : 'all';
  
  const content = data.sanityProjects;
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = content.meta_title ? content.meta_title[intl.locale] : title;
  const metaDescription = content.meta_description ? content.meta_description[intl.locale] : content.headline ? content.headline[intl.locale] : null;  

  const [visibleCases, setVisibleCases] = useState(content.cases);

  useEffect(() => {
    document.querySelector(".tro-page-content")?.scrollTo(0, document.querySelector(".tro-projects-list-container")?.offsetTop - 80);

    if (selected !== 'all') {
      setVisibleCases(content.cases.filter(el => el.tags.find(tag => tag.key === selected)))

      const url = new URL(window.location.href);
      url.searchParams.set('service', selected);
      window.history.replaceState({}, '', url);
    } else {
      setVisibleCases(content.cases)
    }
  }, [selected]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const service = url.searchParams.get('service');

    if (!service) return;

    navigate('/projects', { state: { selected: service } })
  }, []);
  
  return (
    <Layout textWhite={content.lightHeader} title={metaTitle} description={metaDescription} noIndex={content.meta_noindex} path={path}>
      <PageHead
        headline={content.title && content.title[intl.locale]}
        subline={content.headline && content.headline[intl.locale]}
        backgroundImage={content.poster && content.poster.asset ? content.poster.asset.gatsbyImageData.images.fallback.src : null}
        textWhite={content.lightHeader}
      />

      <div className="mb-16">
        <div className="tro-default-padding-x tro-projects-list-container">
          {/* replace taglist below with own filter component */}
          <TagList
            selected={selected}
            teaser={false}
            showAll={true}
            tags={ content.tags }
          />
        </div>
      </div>

      <div className="mb-24">
        <div className="tro-default-padding-x">
          <div className="tro-grid-responsive">
            {content.cases.map((item, index) => (
              <div
                key={index}
                className={`mb-10 col-span-6 large:col-span-4 ${ visibleCases.findIndex(el => el._id === item._id) >= 0 ? '' : 'hidden' }`}
              >
                <TeaserCase
                  item={item}
                  isBanner={false}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      
    </Layout>
  );
};

export default ProjectsPage;


export const query = graphql`
query ProjectsPage {
  sanityProjects {
    title {
      de
      en
    }
    headline {
      de
      en
    }
    meta_title {
      de
      en
    }
    meta_description {
      de
      en
    }
    meta_noindex
    tags {
      key
      title {
        de
        en
      }
    }
    poster {
      asset {
        url
        gatsbyImageData(fit: CROP, placeholder: BLURRED, width: 1800, height: 1200, formats: JPG)
      }
    }
    lightHeader
    cases {
      ... Case
    }
  }
}
`;
